.body {
  width: 100%;
  padding-top: 100px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #1a1a1a;
  padding-bottom: 160px;
  @media only screen and (max-width: 728px) {
    padding-bottom: 60px;
  }
  .banner {
    width: 100%;
    height: 100vh;
    background-image: url("../../assets/image/bg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
  .container {
    width: 100%;
    max-width: 1200px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 120px;
    padding: 0 40px;
    @media only screen and (max-width: 728px) {
      padding: 0 20px;
      gap: 80px;
    }
    .topContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 90px;
      justify-content: space-between;
      align-items: center;
      @media only screen and (max-width: 800px) {
        flex-direction: column;
        gap: 20px;
      }
      .topLeftContainer {
        width: 580px;
        display: flex;
        gap: 20px;
        flex-direction: column;
        @media only screen and (max-width: 800px) {
          width: 100%;
        }
        .topTitle {
          font-family: DrukTextWideTrial, DrukWideTrial-Medium;
          font-weight: 600;
          font-size: 40px;
          color: #ffffff;
          line-height: 48px;
          text-align: left;
          font-style: normal;
          span {
            color: #f77e1f;
          }
        }
        .topDes {
          font-family: DMMono, DMMono;
          font-weight: 400;
          font-size: 18px;
          color: #ffffff;
          line-height: 24px;
          text-align: left;
          font-style: normal;
        }
      }
      .topRightContainer {
        width: 480px;
        height: 480px;
        mix-blend-mode: lighten;
        @media only screen and (max-width: 800px) {
          width: 320px;
          height: 320px;
        }
      }
    }
    .centerContainer {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 40px;
      @media only screen and (max-width: 728px) {
        grid-template-columns: 1fr;
        grid-gap: 20px;
      }
      .itemCard {
        width: 100%;
        min-height: 290px;
        border-radius: 20px;
        padding: 40px;
        display: flex;
        flex-direction: column;
        @media only screen and (max-width: 728px) {
          padding: 20px;
          height: auto;
        }
        .itemImg {
          width: 64px;
          height: 64px;
        }
        .itemTitle {
          margin-top: 20px;
          font-family: DrukWideTrial, DrukWideTrial-Medium;
          font-weight: 600;
          font-size: 24px;
          color: #ffffff;
          line-height: 29px;
          text-align: left;
          font-style: normal;
        }
        .itemDes {
          margin-top: 12px;
          font-family: DMMono, DMMono;
          font-weight: 400;
          font-size: 14px;
          color: #ffffff;
          line-height: 18px;
          text-align: left;
          font-style: normal;
        }
      }
      .profie {
        background-image: linear-gradient(315deg, #00da94 0%, #00b35a 100%);
      }
      .openness {
        background-image: linear-gradient(315deg, #62afff 0%, #3377ff 100%);
      }
      .innovation {
        background-image: linear-gradient(315deg, #df6cf4 0%, #bb3ae6 100%);
      }
      .inclusivity {
        background-image: linear-gradient(315deg, #fcb542 0%, #f77e1f 100%);
      }
      .about {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .aboutTitleContainer {
          display: flex;
          width: 100%;
          gap: 20px;
          align-items: center;
          .aboutImg {
            width: 80px;
            height: 80px;
          }
          .aboutTitle {
            font-family: DrukTextWideTrial, DrukWideTrial-Medium;
            font-weight: 600;
            font-size: 40px;
            color: #ffffff;
            line-height: 48px;
            text-align: left;
            font-style: normal;
          }
        }
        .aboutContent {
          font-family: DMMono, DMMono;
          font-weight: 400;
          font-size: 16px;
          color: #ffffff;
          line-height: 21px;
          text-align: left;
          font-style: normal;
          @media only screen and (max-width: 728px) {
            margin-bottom: 100px;
          }
        }
        .aboutSub {
          display: flex;
          flex-direction: column;
          padding-bottom: 20px;
          padding-top: 10px;
          border-bottom: 1px solid #4d4d4d;

          .aboutSubImgTitle {
            width: 100%;
            display: flex;
            gap: 10px;
            cursor: pointer;
            align-items: center;
            justify-content: space-between;
            .titleInfoContainer {
              display: flex;
              gap: 10px;
              align-items: center;
              .subImg {
                width: 20px;
                height: 20px;
                fill: #fff;
              }
              .subTitle {
                font-family: DMMono, DMMono;
                font-weight: 500;
                font-size: 18px;
                color: #ffffff;
                line-height: 24px;
                text-align: left;
                font-style: normal;
              }
            }
            .expandIcon {
              width: 18px;
              height: 18px;
              fill: #fff;
            }
          }
          .aboutSubContent {
            padding-top: 40px;
            font-family: DMMono, DMMono;
            font-weight: 400;
            font-size: 14px;
            color: #ffffff;
            line-height: 18px;
            text-align: left;
            font-style: normal;
          }
        }
      }
    }
    .bottomContainer {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 40px;
      @media only screen and (max-width: 920px) {
        grid-template-columns: 1fr 1fr;
        gap: 20px;
      }
      @media only screen and (max-width: 728px) {
        grid-template-columns: 1fr;
        gap: 20px;
      }
      .bottomItem {
        width: 100%;
        min-height: 361px;
        background: #262626;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        padding: 40px;
        gap: 20px;
        @media only screen and (max-width: 728px) {
          padding: 20px;
        }
        .bottomImg {
          width: 188px;
          height: 64px;
        }
        .bottomContent {
          text-align: left;
          font-style: normal;
          display: flex;
          flex-direction: column;
          gap: 20px;
          .bottomTitle {
            font-family: DrukWideTrial, DrukWideTrial-Medium;
            font-weight: 600;
            font-size: 24px;
            color: #ffffff;
            line-height: 29px;
          }
          .bottomContent {
            font-family: DMMono, DMMono;
            font-weight: 400;
            font-size: 14px;
            color: #ffffff;
            line-height: 18px;
          }
        }

        .bottomBtn {
          width: 160px;
          height: 45px;
          border-radius: 24px;
          border: 1px solid #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: DrukTextWideTrial, DrukTextWideTrial;
          font-weight: 500;
          font-size: 14px;
          color: #ffffff;
          line-height: 17px;
          text-align: center;
          font-style: normal;
          cursor: pointer;
          &:hover {
            background: #ffffff;
            color: #1f1f1f;
          }
        }
        .disabled {
          cursor: no-drop;
          &:hover {
            background: unset;
            color: #ffffff;
          }
        }
      }
    }
  }
}
