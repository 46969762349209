.body {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  background: linear-gradient(
    180deg,
    #10161d 0%,
    rgba(16, 22, 29, 0.95) 3%,
    rgba(16, 22, 29, 0.8) 12%,
    rgba(16, 22, 29, 0.61) 26%,
    rgba(16, 22, 29, 0.39) 43%,
    rgba(16, 22, 29, 0.2) 62%,
    rgba(16, 22, 29, 0.05) 81%,
    rgba(16, 22, 29, 0) 100%
  );
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  @media only screen and (max-width: 728px) {
    height: 80px;
    padding: unset;
  }
  .container {
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    .logo {
      width: 188px;
      height: 64px;
      object-fit: contain;
      object-position: center;

      @media only screen and (max-width: 728px) {
        width: 141px;
        height: 48px;
      }
    }
    .contact {
      height: 40px;
      padding: 0 15px;
      background: #ffffff;
      border-radius: 20px;
      font-family: DrukTextWideTrial, DrukTextWideTrial;
      font-weight: 500;
      font-size: 14px;
      color: #1f1f1f;
      line-height: 17px;
      text-align: center;
      font-style: normal;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      gap: 9px;
      fill: #1f1f1f;
      &:hover {
        background: #3377ff;
        color: #ffffff;
        fill: #fff;
      }
      .twitterImg {
        width: 20px;
        height: 20px;
      }
      .social {
        font-family: DrukTextWideTrial, DrukTextWideTrial;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        text-align: left;
        font-style: normal;
      }
    }
  }
}
