* {
  box-sizing: border-box;

  ::-webkit-scrollbar {
    display: none;
  }
}

body {
  margin: 0;
  padding: 0;
  background-color: #000;
}

#root {
  margin: 0;
  padding: 0;
  background-color: #000;
}

@font-face {
  font-family: "DrukTextWideTrial";
  src: url("./assets/fonts/DrukTextWide-Medium-Trial.otf") format("truetype");
}

@font-face {
  font-family: "DrukWideTrial-Medium";
  src: url("./assets/fonts/DrukTextWide-Medium-Trial.otf") format("truetype");
}

@font-face {
  font-family: "DMMono";
  src: url("./assets/fonts/DMMono-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "DMMono-Medium";
  src: url("./assets/fonts/DMMono-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "DMMono-Italic";
  src: url("./assets/fonts/DMMono-Italic.ttf") format("truetype");
}
