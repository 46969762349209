.body {
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  z-index: 999;
  .container {
    width: 100%;
    max-width: 1400px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    @media only screen and (max-width: 728px) {
      flex-direction: column;
    }

    .logo {
      width: 204px;
      height: 64px;
      object-fit: contain;
      object-position: center;
    }

    .copyright {
      font-family: DMMono, DMMono;
      font-weight: 400;
      font-size: 16px;
      color: #999999;
      line-height: 21px;
      text-align: center;
      font-style: normal;
    }
  }
}
